import { render, staticRenderFns } from "./GenerateDocumentEditor.vue?vue&type=template&id=edddfb40&"
import script from "./GenerateDocumentEditor.vue?vue&type=script&lang=js&"
export * from "./GenerateDocumentEditor.vue?vue&type=script&lang=js&"
import style0 from "./GenerateDocumentEditor.vue?vue&type=style&index=0&id=edddfb40&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports