<template>
  <div v-if="documentData">
    <div class="col-sm-12 document-generator-container">
      <task-header
        :data-to-show="taskHeaderData"
        :task="documentData.task"
        :task-data="documentData.taskData"
        :related-tasks="documentData.task && documentData.task.additionalData.relatedTasks"
        class="floating-task-header"
        :service="service"
        :client="client || ''"
      />
      <div style="padding-top: 16rem;">
        <div
          class="textEditorContainer row col-xs-6"
          style="max-height: 88vh; display: flex"
        >
          <editor
            v-model="newDocumentContent"
            :init="editorOption"
          />
        </div>
        <div
          style="padding: 20px;"
          class="document-overwiev-data col-lg-6"
        >
          <div style="">
            <template
              v-for="(info, index) in documentDataInfo"
            >
              <div
                v-if="index < documentDataInfo.length / 2"
                :key="index"
              >
                <label
                  :for="index"
                >{{ info['name'] }}: </label><span> {{ info['value'] }}</span>
              </div>
            </template>
          </div>
          <div style="">
            <template
              v-for="(info, index) in documentDataInfo"
            >
              <div
                v-if="index > documentDataInfo.length / 2"
                :key="index"
              >
                <label
                  :for="index"
                >{{ info['name'] }}: </label><span> {{ info['value'] }}</span>
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>
    <BlockingLoader
      :show="showLoader"
    />
  </div>
</template>

<script>
import api from '../../../../api/index'
import WebStorage from '../../../share/WebStorage'
import Editor from '@tinymce/tinymce-vue'
import TaskHeader from '../TaskHeader/TaskHeader'
import DynamicBreadcrumb from '../mixins/DynamicBreadcrumb'
import BlockingLoader from '../../../share/BlockingLoader'
import moment from 'moment'

export default {
  components: {
    BlockingLoader,
    Editor,
    TaskHeader
  },
  mixins: [
    DynamicBreadcrumb
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      newDocumentContent: '',
      showLoader: false,
      type: {
        subject: {
          person: 'Osoba',
          property: 'Mienie',
          vehicle: 'Pojazd'
        },
        task: {
          person: 'osobowa',
          property: 'majątkowa',
          vehicle: 'komunikacyjna'
        }
      },
      editorOption: {
        selector: 'textarea',
        plugins: 'wordcount table preview paste print pagebreak, tabfocus',
        menubar: 'file edit view table print',
        toolbar: 'pagebreak | preview | table | newdocument | bold | italic | underline | strikethrough | alignleft, aligncenter, alignright, alignjustify | styleselect, formatselect, fontselect, fontsizeselect, bullist, numlist, removeformat',
        plugin_preview_width: 630,
        plugin_preview_height: 891,
        height: `550px`,
        width: '565pt',
        indentation: '20pt',
        font_formats: 'Lato CE=Lato CE;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
        language: 'pl'
      },
      documentData: {},
    }
  },
  computed: {
    client () {
      return this.$route.meta.client || this.$route.params.clientSymbol
    },
    events () {
      return {
        generateDocument: `${this.documentData.service}:main:generateDocument`,
        generateAppraisalDocument: `${this.documentData.service}:appraisal:generateDocument`
      }
    },
    prepareDocumentPath () {
      return `${this.documentData.task.createdAt.split('').filter((element, index) => element !== '-' && index < 7).join('')}/${this.documentData.task.number
        .split('').map(element => {
          if (element === '/') {
            return '_'
          } else {
            return element
          }
        }).join('')}`
    },
    documentDataInfo () {
      return this.documentData && this.documentData.fieldsNames && Object.entries(this.documentData.fieldsNames).map(field => {
        return {
          name: field[1],
          value: this.documentData.parametersForDocument[field[0]]
        }
      })
    },
    taskHeaderData () {
      // TODO Get task header data from backend
      let services = ['extgen', 'extpko', 'extavi', 'demofokus', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp', 'extuni']
      if (services.indexOf(this.service) > -1) {
        if (this.documentData && this.documentData.task) {
          return [
            [
              [this.documentData.task.state ? this.documentData.task.state.label : 'b.d.', 'Status:', `${this.documentData.task.id}-document-generator-header-state`],
              [this.documentData.task.author ? (this.documentData.task.author.fullname ? this.documentData.task.author.fullname : (this.documentData.task.author.label ? this.documentData.task.author.label : 'b.d.')) : 'b.d.', 'Autor:', `${this.documentData.task.id}-document-generator-header-author`],
              [this.documentData.task.assignee ? (this.documentData.task.assignee.fullname ? this.documentData.task.assignee.fullname : (this.documentData.task.assignee.label ? this.documentData.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun:', `${this.documentData.task.id}-document-generator-header-assignee`]
            ]
          ]
        } else {
          return []
        }
      } else {
        if (this.documentData && this.documentData.task) {
          return [
            [
              [this.documentData.task.additionalData.firstChangeStateDate ? this.formatDate(this.documentData.task.additionalData.firstChangeStateDate, 'YYYY-MM-DD') : 'b.d', 'Zlecone', `${this.documentData.task.id}-header-firstChangeStateDate`],
              [this.documentData.task.state ? this.documentData.task.state.label : 'b.d.', 'Status:', `${this.documentData.task.id}-document-generator-header-state`],
              [this.documentData.task.author ? (this.documentData.task.author.fullname ? this.documentData.task.author.fullname : (this.documentData.task.author.label ? this.documentData.task.author.label : 'b.d.')) : 'b.d.', 'Autor:', `${this.documentData.task.id}-document-generator-header-author`],
              [this.documentData.task.assignee ? (this.documentData.task.assignee.fullname ? this.documentData.task.assignee.fullname : (this.documentData.task.assignee.label ? this.documentData.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun:', `${this.documentData.task.id}-document-generator-header-assignee`]
            ],
            [
              [this.getType(this.documentData.task.supportedClass, 'task') || 'b.d.', 'Rodzaj:', `${this.documentData.task.id}-document-generator-header-supportedClass-task`],
              [this.documentData.task && this.documentData.task.additionalData && this.documentData.task.additionalData.orderType || 'b.d', 'Zlecenie:', `${this.documentData.task.id}-document-generator-header-orderType`],
              [this.getType(this.documentData.task.supportedClass, 'subject') || 'b.d.', 'Przedmiot:', `${this.documentData.task.id}-document-generator-header-supportedClass-subject`]
            ]
          ]
        } else {
          return []
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$events.on(this.events.generateDocument, this.sendDocument)
      this.$events.on(this.events.generateAppraisalDocument, this.sendDocument)
    })
    this.documentData = this.$store.state.base.documentData !== {} ? WebStorage.getDocumentData() : this.$store.state.base.documentData
    this.newDocumentContent = this.documentData.documentContent

    // dynamic breadcrumb labels
    this.addDynamicBreadcrumbLabel(`${this.documentData.service}_${this.$route.meta.workflowName}_task_details`, this.documentData.task.number ? this.documentData.task.number.split('-')[0] : this.documentData.task.id)
    this.addDynamicBreadcrumbLabel(`${this.documentData.service}_${this.$route.meta.workflowName}_appraisal_task_details`, this.documentData.task.number)
  },
  beforeDestroy: function () {
    WebStorage.removeDocumentData()
  },
  methods: {
    sendDocument () {
      this.showLoader = true
      api.request(this.documentData.service, 'post', '/documents', this.createDataToSend())
        .then((response) => {
          this.$events.$emit('reloadGrid')

          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Wygenerowano dokument'
          })
          WebStorage.removeDocumentData()
          this.showLoader = false
          this.$router.push({ name: `${this.documentData.service}_${this.$route.meta.workflowName}${this.$route.meta.taskType === 'appraisal' ? '_appraisal' : ''}_task_details` })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    formatDate (date, format) {
      return moment(date).format(format)
    },
    getType (subject, field) {
      if (subject) {
        if (subject.includes('Person')) {
          return this.type[field]['person']
        } else if (subject.includes('Property')) {
          return this.type[field]['property']
        } else if (subject.includes('Vehicle')) {
          return this.type[field]['vehicle']
        }
      }
    },
    createDataToSend () {
      return {
        documentId: this.documentData.documentId,
        name: this.documentData.name,
        bucket: this.documentData.service,
        content: this.newDocumentContent,
        path: this.prepareDocumentPath,
        taskId: this.documentData.task.id
      }
    }
  }
}
</script>
<style>
    .textEditorContainer {
        width: 50%;
    }
    .document-overwiev-data {
        display: flex;
        background: #fff;
        width: 52%;
    }
    @media screen and (min-width: 768px) and (max-width: 1367px){
        .textEditorContainer {
            width: 75%;
        }
        .document-overwiev-data {
            display: block;
            width: 27%;
            font-size: 9pt;
        }
    }
    .document-generator-container {
        height: 100%;
        background: #fff;
    }
</style>
